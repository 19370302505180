import * as React from 'react';

import type { NextPage } from 'next';

import { HomePage } from '@itp/myaccount';

const Page: NextPage = () => {
  return <HomePage />;
};

export default Page;
